<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">网签数据</div>
      <div class="list_box">
        <div class="search-box">
          <div class="module-title">网签项目列表</div>
          <div class="region" @click="getRegion">查看该区域网签概览</div>
          <el-form :inline="true" class="demo-form-inline" size="mini">
            <el-form-item label="区域：">
              <el-cascader
                size="large"
                :options="areaData"
                v-model="selectedOptions"
                @change="areaHandleChange"
              >
              </el-cascader>
            </el-form-item>
          </el-form>
        </div>
        <div class="table_box">
          <el-form :inline="true">
            <el-form-item label="项目名称：">
              <el-input v-model="projectName" placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="建设方：">
              <el-input v-model="company" placeholder="请输入建设方"></el-input>
            </el-form-item>
            <el-form-item label="地址：">
              <el-input  v-model="address" placeholder="请输入项目地址"></el-input>
            </el-form-item>
            <div class="btn_box">
              <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData()"
                >查询</el-button
              >
              <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
              >
            </div>
          </el-form>
          <el-table
            ref="table"
            :data="tableData"
            border
            stripe
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="province"
              label="区域"
              width="160"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.province + scope.row.city + scope.row.county
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="项目名称"
              width="130"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="company"
              label="建设方"
              width="160"
              :show-overflow-tooltip="true"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="项目地址"
              width="140"
              :show-overflow-tooltip="true"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="area"
              label="总面积(m²)"
              width="100"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="floorCountTotal"
              label="总层数"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="floorCountUp"
              label="地上总层数"
              align="right"
            ></el-table-column>
            <el-table-column
              prop="floorCountDown"
              label="地下总层数"
              align="right"
            ></el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="detail(scope.$index, scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import { regionDataPlus, CodeToText, TextToCode } from "element-china-area-data"; // 地址级联选择器
export default {
  name: "index",
  data() {
    return {
      projectName: "",
      company:'',
      address:'',
      areaData: [],
      selectedOptions: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      province:'',
      city:'',
      county:''
    };
  },
  created() {
    //默认省市区选择
    //是否选择过
    if(getStore('selprovince')){
      this.selectedOptions[0] = TextToCode[getStore('selprovince')].code;
      this.selectedOptions[1] = TextToCode[getStore('selprovince')][getStore('selcity')].code;
      if(getStore('selcounty').length>0){
        this.selectedOptions[2] = TextToCode[getStore('selprovince')][getStore('selcity')][getStore('selcounty')].code;
      }else{
        this.selectedOptions[2] = '';
      }
      this.province = getStore('selprovince');
      this.city = getStore('selcity');
      this.county = getStore('selcounty');
    }else{
      try {
        //用户默认省市区
        this.selectedOptions[0] = TextToCode[getLocalStore('userinfo').province].code;
        this.selectedOptions[1] = TextToCode[getLocalStore('userinfo').province][getLocalStore('userinfo').city].code;
        this.selectedOptions[2] = TextToCode[getLocalStore('userinfo').province][getLocalStore('userinfo').city][getLocalStore('userinfo').county].code;
        this.province = getLocalStore('userinfo').province;
        this.city = getLocalStore('userinfo').city;
        this.county = getLocalStore('userinfo').county;
      } catch (error) {
        this.selectedOptions = []
        this.province = '';
        this.city = '';
        this.county = '';
      }
      
    }
    this.regionMap();
    // //对省市区数据进行修改
    // this.areaData = regionDataPlus;
    // if(this.areaData[0].label=='全部'){
    //   this.areaData.splice(0,1)
    // }
    // this.areaData.forEach((item,i) => {
    //   if(item.children[0].label=='全部'){
    //      item.children.splice(0,1)
    //   }
    // })

    this.getListData();
  },
  destroyed() {},
  mounted() {},
  methods: {
    ...mapMutations(["addPath","reducePath", "addMenuList", "reduceMenuList"]),
    //选择省市区
    areaHandleChange(value) {
      if(CodeToText[value[0]]){
        this.province = CodeToText[value[0]];
        setStore("selprovince", this.province);
      }else{
        setStore("selprovince", '');
      }
      if(CodeToText[value[1]]){
        this.city = CodeToText[value[1]];
        setStore("selcity", this.city);
      }else{
        setStore("selcity", '');
      }
      if(CodeToText[value[2]]){
        this.county = CodeToText[value[2]];
        this.county = this.county=='全部'?'':this.county
        setStore("selcounty", this.county);
      }else{
        setStore("selcounty", '');
      }
    },
    //获取省市区
    regionMap  (){
        this.axios.request({
            loading: true,
            method: "get", 
            url: '/dms/common/regionMap?isSpecialArea=true',
        }).then((res) => {
          this.areaData = res.data.data;
        })
    },
    //获取表格数据
    getListData() {
      this.axios({
        method: "post",
        url: "/networkProject/pageForProject",
        data: {
          province: this.province,
          city: this.city,
          county: this.county,
          projectName: this.projectName,
          company: this.company,
          address: this.address,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    //查看详情
    detail(index, res) {
      setStore("osData", res);
      this.addMenuList("/osdetail");
      setStore("currentMenu", "/osdetail");
      setStore("osfrom", "/onlineSignature");
      this.$router.push({path: "/osdetail"});
    },
    //切换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getListData();
    },
    //切换页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getListData();
    },
    //搜索
    searchData() {
      this.pageNum = 1;
      this.getListData();
    },
    //重置
    reset() {
      this.projectName = '';
      this.company = '';
      this.address = '';
      this.selectedOptions = [];
      this.province = '';
      this.city = '';
      this.county = '';
    },
    //跳转区域概览
    getRegion(){
      if(this.province==''){
        var province = getLocalStore('userinfo').province;
        setStore("province", province);
      }else{
        setStore("province", this.province);
      }
      if(this.city==''){
        var city = getLocalStore('userinfo').city;
        setStore("city", city);
      }else{
        setStore("city", this.city);
      }
      if(this.county==''){
        var county = getLocalStore('userinfo').county;
        setStore("county", county);
      }else{
        setStore("county", this.county);
      }
      this.addPath('区域概览');
      this.addMenuList("/overview");
      setStore("currentMenu", "/overview");
      this.$router.push({path: '/overview'});
    },
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #646566;
  margin: 16px 0;
}
.list_box {
  width: 100%;
  height: auto;
  background: #ffffff;
}
.search-box {
  padding: 10px 24px 0;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
  .demo-form-inline{
    float: right;
  }
  .region{
    float: right;
    cursor: pointer;
    color: #185BCE;
    line-height: 32px;
    margin-left: 10px;
  }
  .el-form-item {
    margin-bottom: 11px !important;
  }
}
.module-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  float: left;
}
.el-cascader {
  line-height: 30px;
}
/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 12px;
}
/deep/.el-form-item__label {
  margin-top: 2px;
  padding-right: 0px !important;
}
/deep/.el-input__icon {
  line-height: 32px;
}
.table_box {
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
</style>